var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _vm.showViewAsCustomer
            ? _c(
                "v-col",
                { staticClass: "pb-1", attrs: { cols: "4", lg: "3" } },
                [_c("ViewAsCustomer")],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "4", lg: "3" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dateMenu",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    value: _vm.dateRangeText(
                                      _vm.serviceLineBase.dates
                                    ),
                                    label: "Month range",
                                    "prepend-icon": "mdi-calendar-range",
                                    readonly: "",
                                    "single-line": "",
                                    "hide-details": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  color: "info",
                                                                  disabled: !_vm.comparisonValueSelected
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.swapDates
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                    mdi-swap-horizontal\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Swap with Comparison Dates"
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.serviceLineBase.dateMenu,
                    callback: function($$v) {
                      _vm.$set(_vm.serviceLineBase, "dateMenu", $$v)
                    },
                    expression: "serviceLineBase.dateMenu"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      range: "",
                      type: "month",
                      min: _vm.minMonth.local().format(),
                      max: _vm.currentMonth,
                      "show-current": ""
                    },
                    model: {
                      value: _vm.serviceLineBase.dates,
                      callback: function($$v) {
                        _vm.$set(_vm.serviceLineBase, "dates", $$v)
                      },
                      expression: "serviceLineBase.dates"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "4", lg: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.comparisonItems,
                  label: "Compared to",
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  clearable: ""
                },
                model: {
                  value: _vm.comparisonValue,
                  callback: function($$v) {
                    _vm.comparisonValue = $$v
                  },
                  expression: "comparisonValue"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pb-1", attrs: { cols: "4", lg: "3" } },
            [
              _c(
                "v-menu",
                {
                  ref: "dateMenu2",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    value: _vm.dateRangeText(
                                      _vm.serviceLineComparison.dates
                                    ),
                                    disabled: !_vm.comparisonValueSelected,
                                    label: "Month range",
                                    "prepend-icon": "mdi-calendar-range",
                                    readonly: "",
                                    "single-line": "",
                                    "hide-details": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  color: "info",
                                                                  disabled: !_vm.comparisonValueSelected
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.swapDates
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                    mdi-swap-horizontal\n                  "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Swap with Main Dates")
                                                ])
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.serviceLineComparison.dateMenu,
                    callback: function($$v) {
                      _vm.$set(_vm.serviceLineComparison, "dateMenu", $$v)
                    },
                    expression: "serviceLineComparison.dateMenu"
                  }
                },
                [
                  _c("v-date-picker", {
                    attrs: {
                      range: "",
                      type: "month",
                      min: _vm.minMonth.local().format(),
                      max: _vm.currentMonth,
                      "show-current": ""
                    },
                    model: {
                      value: _vm.serviceLineComparison.dates,
                      callback: function($$v) {
                        _vm.$set(_vm.serviceLineComparison, "dates", $$v)
                      },
                      expression: "serviceLineComparison.dates"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center align-end",
              attrs: { cols: "12" }
            },
            [
              _c("div", { staticClass: "text-h4" }, [
                _vm._v("\n        " + _vm._s(_vm.title1) + "\n      ")
              ]),
              _c("div", { staticClass: "text-h6 font-italic" }, [
                _vm._v("\n        " + _vm._s(_vm.title2) + "\n      ")
              ])
            ]
          ),
          _c(
            "v-col",
            { staticClass: "dx-viewport", attrs: { cols: "12" } },
            [
              !_vm.serviceLineBase.serviceLinesLoading &&
              _vm.serviceLineBase.serviceLines
                ? _c(
                    "DxTreeList",
                    {
                      style: {
                        maxHeight:
                          _vm.windowMixin_containerMinHeight - 100 + "px"
                      },
                      attrs: {
                        id: "serviceLines",
                        "data-source": _vm.serviceLineBase.serviceLines,
                        "root-value": -1,
                        "expanded-row-keys": _vm.expandedRowKeys,
                        "allow-column-reordering": true,
                        "allow-column-resizing": true,
                        "column-resizing-mode": "nextColumn",
                        "show-row-lines": true,
                        "show-borders": true,
                        "column-auto-width": true,
                        "filter-mode": "fullBranch",
                        "key-expr": "index",
                        "parent-id-expr": "parentIndex"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "CTReceived",
                            fn: function(ref) {
                              var options = ref.data
                              return [
                                _c("ServiceLineReportCellTemplate", {
                                  attrs: {
                                    "metric-name": "received",
                                    "cell-data": options.data,
                                    "comparison-cell-data":
                                      _vm.comparisonValue === "MMS Benchmark"
                                        ? {}
                                        : _vm.getComparisonServiceLine(
                                            options.data
                                          ),
                                    format: "number"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "CTReceivedRate",
                            fn: function(ref) {
                              var options = ref.data
                              return [
                                _c("ServiceLineReportCellTemplate", {
                                  attrs: {
                                    "metric-name": "receivedRate",
                                    "cell-data": options.data,
                                    "comparison-cell-data":
                                      _vm.comparisonValue === "MMS Benchmark"
                                        ? {}
                                        : _vm.getComparisonServiceLine(
                                            options.data
                                          ),
                                    format: "percent",
                                    "color-code-comparison": false
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "CTReadRate",
                            fn: function(ref) {
                              var options = ref.data
                              return [
                                _c("ServiceLineReportCellTemplate", {
                                  attrs: {
                                    "metric-name": "readRate",
                                    "cell-data": options.data,
                                    "comparison-cell-data": _vm.getComparisonServiceLine(
                                      options.data
                                    ),
                                    format: "percent"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "CTOpenRate",
                            fn: function(ref) {
                              var options = ref.data
                              return [
                                _c("ServiceLineReportCellTemplate", {
                                  attrs: {
                                    "metric-name": "openRate",
                                    "cell-data": options.data,
                                    "comparison-cell-data": _vm.getComparisonServiceLine(
                                      options.data
                                    ),
                                    format: "percent"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "CTClickRate",
                            fn: function(ref) {
                              var options = ref.data
                              return [
                                _c("ServiceLineReportCellTemplate", {
                                  attrs: {
                                    "metric-name": "clickRate",
                                    "cell-data": options.data,
                                    "comparison-cell-data": _vm.getComparisonServiceLine(
                                      options.data
                                    ),
                                    format: "percent"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "CTClickToOpenRate",
                            fn: function(ref) {
                              var options = ref.data
                              return [
                                _c("ServiceLineReportCellTemplate", {
                                  attrs: {
                                    "metric-name": "clickToOpenRate",
                                    "cell-data": options.data,
                                    "comparison-cell-data": _vm.getComparisonServiceLine(
                                      options.data
                                    ),
                                    format: "percent"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        651724883
                      )
                    },
                    [
                      _c("DxFilterRow", { attrs: { visible: true } }),
                      _c("DxRemoteOperations", {
                        attrs: {
                          filtering: true,
                          sorting: true,
                          grouping: true
                        }
                      }),
                      _c("DxSearchPanel", { attrs: { visible: true } }),
                      _c("DxHeaderFilter", { attrs: { visible: true } }),
                      _c("DxFilterPanel", { attrs: { visible: true } }),
                      _c("DxSelection", { attrs: { mode: "single" } }),
                      _c("DxColumn", {
                        attrs: {
                          "data-field": "displayName",
                          caption: "Service Line/Specialty",
                          "allow-hiding": false,
                          "allow-reordering": false,
                          fixed: true
                        }
                      }),
                      _c("DxColumn", {
                        attrs: {
                          "data-field": "received",
                          caption: "Received",
                          "cell-template": "CTReceived"
                        }
                      }),
                      _c(
                        "DxColumn",
                        {
                          attrs: {
                            "data-field": "receivedRate",
                            caption: "Received Distribution (%)",
                            "data-type": "number",
                            format: "percent",
                            "cell-template": "CTReceivedRate"
                          }
                        },
                        [
                          _c("DxFormat", {
                            attrs: { type: "fixedPoint", precision: 2 }
                          }),
                          _c("DxHeaderFilter", {
                            attrs: { "group-interval": 10 }
                          })
                        ],
                        1
                      ),
                      _c(
                        "DxColumn",
                        {
                          attrs: {
                            "data-field": "readRate",
                            caption: "Read Rate (>2s) (%)",
                            "data-type": "number",
                            format: "percent",
                            "cell-template": "CTReadRate"
                          }
                        },
                        [
                          _c("DxFormat", {
                            attrs: { type: "fixedPoint", precision: 2 }
                          })
                        ],
                        1
                      ),
                      _c(
                        "DxColumn",
                        {
                          attrs: {
                            "data-field": "openRate",
                            caption: "Open Rate (%)",
                            "data-type": "number",
                            format: "percent",
                            "cell-template": "CTOpenRate"
                          }
                        },
                        [
                          _c("DxFormat", {
                            attrs: { type: "fixedPoint", precision: 2 }
                          })
                        ],
                        1
                      ),
                      _c(
                        "DxColumn",
                        {
                          attrs: {
                            "data-field": "clickRate",
                            caption: "Click Rate (%)",
                            "data-type": "number",
                            format: "percent",
                            "cell-template": "CTClickRate"
                          }
                        },
                        [
                          _c("DxFormat", {
                            attrs: { type: "fixedPoint", precision: 2 }
                          })
                        ],
                        1
                      ),
                      _c("DxColumnChooser", {
                        attrs: {
                          enabled: true,
                          "allow-search": false,
                          mode: "select"
                        }
                      })
                    ],
                    1
                  )
                : _vm.serviceLineBase.serviceLinesLoading
                ? _c("v-skeleton-loader", { attrs: { type: "image@3" } })
                : _c("div", [
                    _vm._v(
                      "\n        Select a date range to see AMA Service Line data.\n      "
                    )
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }